import React, { useState } from "react";
import {
  parseFile,
  processHeaderFile,
  processDetailsFile,
  calculateRawGrossAndVat,
} from "./fileProcessor";
import "bootstrap/dist/css/bootstrap.min.css";

const FileUploader = ({ handleHeaderResults }) => {
  const [headerDlySaleValue, setHeaderDlySaleValue] = useState(0);
  const [headerDlySale, setHeaderDlySale] = useState(0);
  const [headerServCharge, setHeaderServCharge] = useState(0);
  const [headerVat, setHeaderVat] = useState(0);
  const [headerNonVat, setHeaderNonVat] = useState(0);
  const [headerTotDisc, setHeaderTotDisc] = useState(0); // Added for TOTDISC
  const [headerTotRef, setHeaderTotRef] = useState(0); // Added for TOTREF + TOTCAN
  const [detailsSalesSum, setDetailsSalesSum] = useState(0);
  const [rawGrossValue, setRawGrossValue] = useState(0);
  const [rawVatValue, setRawVatValue] = useState(0);
  const [isRawGrossComputed, setIsRawGrossComputed] = useState(false);
  const [transCount, setTransCount] = useState(0);
  const [trancntSum, setTrancntSum] = useState(0);

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const rows = parseFile(text);

        if (type === "HEADER") {
          const {
            dlySaleValue,
            vat,
            nonVat,
            dlysale,
            servcharge,
            beginv,
            endinv,
            transCount,
            headerTotDisc, // Capture TOTDISC value
            headerTotRef, // Capture TOTREF + TOTCAN value
          } = processHeaderFile(rows);
          const { rawGross, rawVat } = calculateRawGrossAndVat(rows);

          // Set state for header file results
          setRawGrossValue(rawGross);
          setRawVatValue(rawVat);
          setIsRawGrossComputed(rawGross > 0);
          setHeaderDlySaleValue(dlySaleValue);
          setHeaderDlySale(dlysale);
          setHeaderServCharge(servcharge);
          setHeaderVat(vat);
          setHeaderNonVat(nonVat);
          setHeaderTotDisc(headerTotDisc); // Set TOTDISC state
          setHeaderTotRef(headerTotRef); // Set TOTREF + TOTCAN state
          setTransCount(transCount);

          // Pass results to parent component including headerDlySale
          handleHeaderResults({
            dlySaleValue,
            vat,
            dlysale,
            transCount,
            rawGrossValue: rawGross,
            rawVatValue: rawVat,
            rawVatableSalesValue: dlySaleValue,
            headerDlySale,
            headerNonVat: nonVat,
            headerServCharge: servcharge,
            headerTotDisc,
            headerTotRef,
          });
        } else if (type === "DETAILS") {
          const { salesSum, trancntSum } = processDetailsFile(rows);
          setDetailsSalesSum(salesSum);
          setTrancntSum(trancntSum);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <div className="mb-4">
        <h3>HEADER</h3>
        <input
          type="file"
          accept=".txt"
          onChange={(e) => handleFileUpload(e, "HEADER")}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <h3>DETAILS</h3>
        <input
          type="file"
          accept=".txt"
          onChange={(e) => handleFileUpload(e, "DETAILS")}
          className="form-control"
        />
      </div>

      <div className="mt-4">
        <h3>Comparison Result</h3>
        <table className="table table-striped table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Description</th>
              <th>Value</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Net Computation</td>
              <td className="text-end">
                {Math.abs(headerDlySaleValue - detailsSalesSum) < 0.01 ? (
                  <span className="text-success">Tally</span>
                ) : (
                  <span className="text-danger">
                    Discrepancy (
                    {Math.abs(headerDlySaleValue - detailsSalesSum).toFixed(2)}{" "}
                    )
                  </span>
                )}
              </td>
              <td className="text-center">
                {Math.abs(headerDlySaleValue - detailsSalesSum) < 0.01 ? (
                  <span className="badge bg-success">Equal</span>
                ) : (
                  <span className="badge bg-danger">Not Equal</span>
                )}
              </td>
            </tr>
            <tr>
              <td>TRANS COUNT</td>
              <td className="text-end">
                {transCount}
                {transCount !== trancntSum && (
                  <span className="text-danger">
                    (Mismatch: {transCount} vs {trancntSum})
                  </span>
                )}
              </td>
              <td className="text-center">
                {transCount === trancntSum ? (
                  <span className="badge bg-success">Match</span>
                ) : (
                  <span className="badge bg-danger">Mismatch</span>
                )}
              </td>
            </tr>
            <tr>
              <td>RAW GROSS</td>
              <td className="text-end">
                {isRawGrossComputed ? rawGrossValue.toFixed(2) : "N/A"}
              </td>
              <td className="text-center">
                <span className="badge bg-success">
                  {isRawGrossComputed ? "Computed" : "Not Computed"}
                </span>
              </td>
            </tr>
            <tr>
              <td>DLYSALE</td>
              <td className="text-end">{headerDlySale.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>SERVCHARGE</td>
              <td className="text-end">{headerServCharge.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>VAT</td>
              <td className="text-end">{headerVat.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>NON-VAT</td>
              <td className="text-end">{headerNonVat.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>TOTDISC</td>
              <td className="text-end">{headerTotDisc.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>TOTREF + TOTCAN</td>
              <td className="text-end">{headerTotRef.toFixed(2)}</td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
            <tr>
              <td>DLYSALE + VAT + SERVCHARGE</td>
              <td className="text-end">
                {(headerVat + headerDlySale + headerServCharge).toFixed(2)}
              </td>
              <td className="text-center">
                <span className="badge bg-success">Read-Only</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileUploader;
