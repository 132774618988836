import React, { useState, useMemo } from "react";
import EODFileUploader from "./EODFileUploader";
import FileUploader from "./FileUploader";
import MultiFileUploader from "./MultiFileUploader";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MainFileUploader = () => {
  const [grossSales, setGrossSales] = useState(0); // From EOD
  const [vatAmount, setVatAmount] = useState(0); // From EOD
  const [vatableSales, setVatableSales] = useState(0); // From EOD
  const [nonVatSales, setNonVatSales] = useState(0); // From EOD
  const [vatExemptSales, setVatExemptSales] = useState(0); // From EOD
  const [vatExemptAmount, setVatExemptAmount] = useState(0); // From EOD
  const [discounts, setDiscount] = useState(0); // From EOD
  const [snrCitDisc, setSnrCitDisc] = useState(0); // From EOD
  const [pwdDisc, setPwdDisc] = useState(0); // From EOD
  const [refundAmount, setRefundAmount] = useState(0); // From EOD
  const [servchargeAmount, setServchargeAmount] = useState(0); // From EOD
  const [cashAmount, setCashAmount] = useState(0); // From EOD
  const [cardAmount, setCardAmount] = useState(0); // From EOD
  const [dCardAmount, setDcardAmount] = useState(0); // From EOD
  const [ePayAmount, setEpayAmount] = useState(0); // From EOD
  const [onlineSales, setOnlineSales] = useState(0); // From EOD
  const [gcSales, setGcSales] = useState(0); // From EOD
  const [checkSales, setCheckSales] = useState(0); // From EOD
  const [foodPandaSales, setFoodPandaSales] = useState(0); // From EOD
  const [grabSales, setGrabSales] = useState(0); // From EOD
  const [rawGrossValue, setRawGrossValue] = useState(0); // From FileUploader
  const [rawVatValue, setRawVatValue] = useState(0);
  const [headerNonVat, setHeaderNonVatValue] = useState(0);
  const [rawVatableSalesValue, setRawVatableSalesValue] = useState(0);
  const [headerServCharge, setHeaderServCharge] = useState(0);
  const [headerTotDisc, setHeaderTotDisc] = useState(0);
  const [headerTotRef, setHeaderTotRef] = useState(0);
  const [rawDlyValue, setRawDlyValue] = useState(0);
  const [multiFileResults, setMultiFileResults] = useState([]); // MultiFileUploader

  const handleHeaderResults = ({
    rawGrossValue, // This should be calculated in FileUploader
    rawVatValue, // This should also be calculated in FileUploader
    rawVatableSalesValue: dlySaleValue, // Ensure this is set to the correct value
    headerDlySale, // Add this line
    headerNonVat,
    rawDlyValue,
    headerServCharge,
    headerTotDisc,
    headerTotRef,
  }) => {
    console.log(headerServCharge);
    setRawGrossValue(Number(rawGrossValue));
    setRawVatValue(Number(rawVatValue));
    setHeaderNonVatValue(Number(headerNonVat));
    setRawVatableSalesValue(Number(dlySaleValue - rawVatValue)); // Ensure this is set correctly
    setRawDlyValue(Number(rawDlyValue)); // Ensure rawDlyValue is also set
    setHeaderServCharge(Number(headerServCharge));
    setHeaderTotDisc(Number(headerTotDisc));
    setHeaderTotRef(Number(headerTotRef));
  };

  const handleGrossSales = (salesData) => {
    // EOD
    // console.log(salesData); // Log the incoming sales data to check if all fields are available
    const {
      grossSales = 0,
      vatAmount = 0,
      vatableSales = 0,
      nonVatSales = 0,
      vatExemptSales = 0,
      vatExemptAmount = 0,
      discounts = 0,
      snrCitDisc = 0,
      pwdDisc = 0,
      refundAmount = 0,
      servchargeAmount = 0,
      cashAmount = 0,
      cardAmount = 0,
      dCardAmount = 0,
      ePayAmount = 0,
      onlineSales = 0,
      checkSales = 0,
      gcSales = 0,
      foodPandaSales = 0,
      grabSales = 0,
    } = salesData;

    setGrossSales(grossSales);
    setVatAmount(vatAmount);
    setVatableSales(vatableSales);
    setNonVatSales(nonVatSales);
    setVatExemptSales(vatExemptSales);
    setVatExemptAmount(vatExemptAmount);
    setDiscount(discounts);
    setSnrCitDisc(snrCitDisc);
    setPwdDisc(pwdDisc);
    setRefundAmount(refundAmount);
    setServchargeAmount(servchargeAmount);
    setCashAmount(cashAmount);
    setCardAmount(cardAmount);
    setDcardAmount(dCardAmount);
    setEpayAmount(ePayAmount);
    setOnlineSales(onlineSales);
    setGcSales(gcSales);
    setCheckSales(checkSales);
    setFoodPandaSales(foodPandaSales);
    setGrabSales(grabSales);
  };

  const computeSales = (flag) => {
    return (field) =>
      multiFileResults
        .filter((res) => res.slsFlag === flag)
        .reduce((sum, res) => sum + parseFloat(res[field] || 0), 0);
  };

  const salesByFlag = useMemo(() => {
    const getSales = computeSales("S");
    const getRefunds = computeSales("R");

    return {
      grossSalesValue: getSales("grossSales") || 0,
      vatAmount: getSales("vatAmount") - getRefunds("vatAmount") || 0,
      vatSales: getSales("vatableSales") - getRefunds("vatableSales") || 0,
      nonVatSales: getSales("nonVatSales") - getRefunds("nonVatSales") || 0,
      vatExemptSales:
        Math.abs(getSales("vatExemptSales")) -
          Math.abs(getRefunds("vatExemptSales")) || 0,
      vatExemptAmount: getSales("vatExemptAmount") || 0,
      nonVatSales: getSales("nonvatSales") || 0,
      discountAmount:
        (getSales("discountAmount") || getRefunds("discountAmount") || 0) +
        (Math.abs(getSales("snrCitDisc")) -
          Math.abs(getRefunds("snrCitDisc")) || 0) +
        (Math.abs(getSales("pwdDisc")) - Math.abs(getRefunds("pwdDisc")) || 0),
      snrCitDisc:
        Math.abs(getSales("snrCitDisc")) - Math.abs(getRefunds("snrCitDisc")) ||
        0,
      pwdDisc:
        Math.abs(getSales("pwdDisc")) - Math.abs(getRefunds("pwdDisc")) || 0,
      refundAmount: getSales("refundAmount") || getRefunds("grossSales") || 0,
      servchargeAmount:
        getSales("servchargeAmount") || getRefunds("servchargeAmount") || 0,
      cashAmount:
        Math.abs(getSales("cashAmount")) - Math.abs(getRefunds("cashAmount")) ||
        0,
      cardAmount:
        getSales("cardAmount") - Math.abs(getRefunds("cardAmount")) || 0,
      dCardAmount:
        getSales("dCardAmount") - Math.abs(getRefunds("dCardAmount")) || 0,
      ePayAmount:
        getSales("ePayAmount") - Math.abs(getRefunds("ePayAmount")) || 0,
      onlineSales:
        getSales("onlineSales") - Math.abs(getRefunds("onlineSales")) || 0,
      checkSales:
        getSales("checkAmount") - Math.abs(getRefunds("checkAmount")) || 0,
      gcSales: getSales("gcAmount") - Math.abs(getRefunds("gcAmount")) || 0,
      foodPandaSales:
        getSales("foodPandaSales") - Math.abs(getRefunds("foodPandaSales")) ||
        0,
      grabSales: getSales("grabSales") - Math.abs(getRefunds("grabSales")) || 0,
    };
  }, [multiFileResults]);

  const isMatching = (calculatedValue, csvValue) =>
    Math.abs(calculatedValue - Number(csvValue)) < 0.01;

  const priorityDescriptions = [
    "GROSS_SLS",
    "VAT_AMNT",
    "VATABLE_SLS",
    "NONVAT_SLS",
  ];

  const formatNumber = (value) => {
    if (value || value !== 0) {
      return value.toLocaleString();
    }
    return "-";
  };

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h1 style={{ textAlign: "center" }}>AYALA TEXT FILE CHECKER</h1>
      <FileUploader handleHeaderResults={handleHeaderResults} />
      <MultiFileUploader
        handleMultiFileResults={setMultiFileResults}
        handleGrossSales={handleGrossSales}
      />
      <EODFileUploader handleGrossSales={handleGrossSales} />

      {/* Display Consolidated Results */}

      <div className="mt-5">
        <hr />
        <h3>Cross Checking Results</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Description</th>
              <th>Raw Gross (Header)</th>
              <th>Hourly Files Gross Sales</th>
              <th>Gross Sales (EOD)</th>
              <th>Discrepancy</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {[
              [
                "GROSS_SLS",
                rawGrossValue,
                salesByFlag.grossSalesValue,
                grossSales,
              ],
              ["VAT_AMNT", rawVatValue, salesByFlag.vatAmount, vatAmount],
              [
                "VATABLE_SLS",
                rawVatableSalesValue,
                salesByFlag.vatSales,
                vatableSales,
              ],
              [
                "NONVAT_SLS",
                headerNonVat,
                salesByFlag.nonVatSales,
                nonVatSales,
              ],
              ["VATEXEMPT_SLS", "", salesByFlag.vatExemptSales, vatExemptSales],
              [
                "VATEXEMPT_AMNT",
                "",
                salesByFlag.vatExemptAmount,
                vatExemptAmount,
              ],
              [
                "DISCOUNTS",
                headerTotDisc,
                salesByFlag.discountAmount,
                discounts,
              ],
              ["SNRCIT_DISC", "", salesByFlag.snrCitDisc, snrCitDisc],
              ["PWD_DISC", "", salesByFlag.pwdDisc, pwdDisc],
              [
                "REFUND_AMT",
                headerTotRef,
                salesByFlag.refundAmount,
                refundAmount,
              ],
              [
                "SCHRGE_AMT",
                headerServCharge,
                salesByFlag.servchargeAmount,
                servchargeAmount,
              ],
              ["CASH_SLS", "", salesByFlag.cashAmount, cashAmount],
              ["CARD_SLS", "", salesByFlag.cardAmount, cardAmount],
              ["DCARD_SLS", "", salesByFlag.dCardAmount, dCardAmount],
              ["EPAY_SLS", "", salesByFlag.ePayAmount, ePayAmount],
              ["ONLINE_SLS", "", salesByFlag.onlineSales, onlineSales],
              ["CHECK_SLS", "", salesByFlag.checkSales, checkSales],
              ["GC_SLS", "", salesByFlag.gcSales, gcSales],
              ["FOODPANDA_SLS", "", salesByFlag.foodPandaSales, foodPandaSales],
              ["GRAB_SLS", "", salesByFlag.grabSales, grabSales],
            ].map(([description, raw, hourly, eod], index) => (
              <tr key={index}>
                <td>{description}</td>
                <td>{formatNumber(raw)}</td>
                <td>{formatNumber(hourly)}</td>
                <td>{formatNumber(eod)}</td>
                <td>
                  {raw && eod // Check if both raw and eod are present
                    ? (raw - eod).toFixed(2) // Display the discrepancy when both values are present
                    : "-"}{" "}
                  {/* If either raw or eod is missing, display "-" */}
                </td>
                <td>
                  {priorityDescriptions.includes(description) ? (
                    description === "VATABLE_SLS" ? (
                      isMatching(hourly, eod) &&
                      isMatching(hourly, raw - headerNonVat) ? (
                        <span className="badge bg-success">Match</span>
                      ) : (
                        <span className="badge bg-danger">Mismatch</span>
                      )
                    ) : isMatching(hourly, eod) && isMatching(hourly, raw) ? (
                      <span className="badge bg-success">Match</span>
                    ) : (
                      <span className="badge bg-danger">Mismatch</span>
                    )
                  ) : isMatching(hourly, eod) || isMatching(hourly, raw) ? (
                    <span className="badge bg-success">Match</span>
                  ) : (
                    <span className="badge bg-danger">Mismatch</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MainFileUploader;
