import React, { useState } from "react";
import Papa from "papaparse";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./file.css";

const EODFileUploader = ({ handleGrossSales }) => {
  const [results, setResults] = useState([]);

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    let allResults = [];

    files.forEach((file) => {
      Papa.parse(file, {
        header: false,
        complete: (result) => {
          const data = result.data;
          const processedResults = processFileData(data, file.name);
          allResults = [...allResults, ...processedResults];

          if (allResults.length) {
            setResults(allResults);
            // Compute total gross sales
            const grossSalesRow = processedResults.find(
              (r) => r.type === "GROSS_SLS"
            );
            const vatAmountRow = processedResults.find(
              (r) => r.type === "VAT_AMNT"
            );
            const vatableSaleRow = processedResults.find(
              (r) => r.type === "VATABLE_SLS"
            );
            const NonVatSaleRow = processedResults.find(
              (r) => r.type === "NONVAT_SLS"
            );
            const vatExemptSaleRow = processedResults.find(
              (r) => r.type === "VATEXEMPT_SLS"
            );
            const vatExemptAmountRow = processedResults.find(
              (r) => r.type === "VATEXEMPT_AMNT"
            );
            const discountsRow = processedResults.find(
              (r) => r.type === "DISCOUNTS"
            );
            const refundAmountRow = processedResults.find(
              (r) => r.type === "REFUND_AMT"
            );
            const snrCitDiscRow = processedResults.find(
              (r) => r.type === "SNRCIT_DISC"
            );
            const pwdDiscRow = processedResults.find(
              (r) => r.type === "PWD_DISC"
            );
            const servchargeAmountRow = processedResults.find(
              (r) => r.type === "SCHRGE_AMT"
            );
            const cashSalesRow = processedResults.find(
              (r) => r.type === "CASH_SLS"
            );
            const ePaySalesRow = processedResults.find(
              (r) => r.type === "EPAY_SLS"
            );
            const cardRow = processedResults.find((r) => r.type === "CARD_SLS");
            const dCardRow = processedResults.find(
              (r) => r.type === "DCARD_SLS"
            );
            const onlineSalesRow = processedResults.find(
              (r) => r.type === "ONLINE_SLS"
            );
            const checkRow = processedResults.find(
              (r) => r.type === "CHECK_SLS"
            );
            const gcSalesRow = processedResults.find(
              (r) => r.type === "GC_SLS"
            );
            const grabSalesRow = processedResults.find(
              (r) => r.type === "GRAB_SLS"
            );
            const foodPandaSalesRow = processedResults.find(
              (r) => r.type === "FOODPANDA_SLS"
            );

            const grossSales = grossSalesRow
              ? parseFloat(grossSalesRow.csvValue) || 0
              : 0;
            const vatAmount = vatAmountRow
              ? parseFloat(vatAmountRow.csvValue) || 0
              : 0;
            const vatableSales = vatableSaleRow
              ? parseFloat(vatableSaleRow.csvValue) || 0
              : 0;
            const nonVatSales = NonVatSaleRow
              ? parseFloat(NonVatSaleRow.csvValue) || 0
              : 0;
            const vatExemptSales = vatExemptSaleRow
              ? parseFloat(vatExemptSaleRow.csvValue) || 0
              : 0;
            const vatExemptAmount = vatExemptAmountRow
              ? parseFloat(vatExemptAmountRow.csvValue) || 0
              : 0;
            const discounts = discountsRow
              ? parseFloat(discountsRow.csvValue) || 0
              : 0;
            const snrCitDisc = snrCitDiscRow
              ? parseFloat(snrCitDiscRow.csvValue) || 0
              : 0;
            const pwdDisc = pwdDiscRow
              ? parseFloat(pwdDiscRow.csvValue) || 0
              : 0;
            const refundAmount = refundAmountRow
              ? parseFloat(refundAmountRow.csvValue) || 0
              : 0;
            const servchargeAmount = servchargeAmountRow
              ? parseFloat(servchargeAmountRow.csvValue) || 0
              : 0;
            const cashAmount = cashSalesRow
              ? parseFloat(cashSalesRow.csvValue) || 0
              : 0;
            const cardAmount = cardRow ? parseFloat(cardRow.csvValue) || 0 : 0;
            const dCardAmount = dCardRow
              ? parseFloat(dCardRow.csvValue) || 0
              : 0;
            const ePayAmount = ePaySalesRow
              ? parseFloat(ePaySalesRow.csvValue) || 0
              : 0;
            const onlineSales = onlineSalesRow
              ? parseFloat(onlineSalesRow.csvValue) || 0
              : 0;
            const checkSales = checkRow
              ? parseFloat(checkRow.csvValue) || 0
              : 0;
            const gcSales = gcSalesRow
              ? parseFloat(gcSalesRow.csvValue) || 0
              : 0;
            const foodPandaSales = foodPandaSalesRow
              ? parseFloat(foodPandaSalesRow.csvValue) || 0
              : 0;
            const grabSales = grabSalesRow
              ? parseFloat(grabSalesRow.csvValue) || 0
              : 0;

            handleGrossSales({
              grossSales,
              vatAmount,
              vatableSales,
              nonVatSales,
              vatExemptSales,
              vatExemptAmount,
              discounts,
              snrCitDisc,
              pwdDisc,
              refundAmount,
              servchargeAmount,
              cashAmount,
              cardAmount,
              dCardAmount,
              ePayAmount,
              onlineSales,
              checkSales,
              gcSales,
              foodPandaSales,
              grabSales,
            });
          }
        },
      });
    });
  };

  const processFileData = (data, fileName) => {
    const processedResults = [];

    data.forEach((row) => {
      const index0 = row[0]?.trim();
      const index1 = row[1]?.trim();

      if (index0 && index1) {
        const value = parseFloat(index1);
        if (isNaN(value)) return; // Skip invalid data

        processedResults.push({
          fileName,
          type: index0,
          csvValue: value,
        });
      }
    });

    const computeValue = (type) => {
      const values = processedResults
        .filter((r) => r.type === type)
        .map((r) => r.csvValue || 0);
      const total = values.reduce((acc, val) => acc + val, 0);
      return total;
    };

    // Calculate GROSS_SLS
    const refundAmountRow = processedResults.find(
      (r) => r.type === "REFUND_AMT"
    );
    const servchargeAmountRow = processedResults.find(
      (r) => r.type === "SCHRGE_AMT"
    );
    const NoRefundAmountRow = processedResults.find(
      (r) => r.type === "NO_REFUND"
    );
    const VatAmountRow = processedResults.find((r) => r.type === "VAT_AMNT");
    const VatableSaleRow = processedResults.find(
      (r) => r.type === "VATABLE_SLS"
    );
    const NonVatSaleRow = processedResults.find((r) => r.type === "NONVAT_SLS");
    const VatExemptSaleRow = processedResults.find(
      (r) => r.type === "VATEXEMPT_SLS"
    );
    const VatExemptAmountRow = processedResults.find(
      (r) => r.type === "VATEXEMPT_AMNT"
    );
    const grossSalesRow = processedResults.find((r) => r.type === "GROSS_SLS");
    const discountRow = processedResults.find((r) => r.type === "DISCOUNTS");
    const snrCitDiscRow = processedResults.find(
      (r) => r.type === "SNRCIT_DISC"
    );
    const pwdDiscRow = processedResults.find((r) => r.type === "PWD_DISC");
    const noDiscountRow = processedResults.find((r) => r.type === "NO_DISC");
    const cardSalesRow = processedResults.find((r) => r.type === "CARD_SLS");
    const dcardSalesRow = processedResults.find((r) => r.type === "DCARD_SLS");
    const ePaySalesRow = processedResults.find((r) => r.type === "EPAY_SLS");
    const onlineSalesRow = processedResults.find(
      (r) => r.type === "ONLINE_SLS"
    );
    const checkSalesRow = processedResults.find((r) => r.type === "CHECK_SLS");
    const gcSalesRow = processedResults.find((r) => r.type === "GC_SLS");
    const foodPandaSalesRow = processedResults.find(
      (r) => r.type === "FOODPANDA_SLS"
    );
    const grabSalesRow = processedResults.find((r) => r.type === "GRAB_SLS");
    const cashAmount = processedResults.find((r) => r.type === "CASH_SLS");

    if (VatAmountRow) {
      VatAmountRow.result = "Read-Only";
      VatAmountRow.computeValue = "N/A";
      VatAmountRow.csvValue.toFixed(2);
    }

    if (VatExemptSaleRow) {
      VatExemptSaleRow.result = "Read-Only";
      VatExemptSaleRow.computeValue = "N/A";
      VatExemptSaleRow.csvValue.toFixed(2);
    }

    if (VatableSaleRow) {
      VatableSaleRow.result = "Read-Only";
      VatableSaleRow.computeValue = "N/A";
      VatableSaleRow.csvValue.toFixed(2);
    }

    if (NonVatSaleRow) {
      NonVatSaleRow.result = "Read-Only";
      NonVatSaleRow.computeValue = "N/A";
      NonVatSaleRow.csvValue.toFixed(2);
    }

    if (VatExemptAmountRow) {
      VatExemptAmountRow.result = "Read-Only";
      VatExemptAmountRow.computeValue = "N/A";
      VatExemptAmountRow.csvValue.toFixed(2);
    }

    if (NoRefundAmountRow) {
      cashAmount.result = "Read-Only";
      cashAmount.computeValue = "N/A";
      cashAmount.csvValue.toFixed(2);
    }

    if (NoRefundAmountRow) {
      NoRefundAmountRow.result = "Read-Only "; // Keep this always "Success"
      NoRefundAmountRow.computedValue = "N/A";
      NoRefundAmountRow.csvValue.toFixed(2);
    }

    if (refundAmountRow) {
      refundAmountRow.result = "Read-Only "; // Keep this always "Success"
      refundAmountRow.computedValue = "N/A";
      refundAmountRow.csvValue.toFixed(2);
    }

    if (servchargeAmountRow) {
      servchargeAmountRow.result = "Read-Only "; // Keep this always "Success"
      servchargeAmountRow.computedValue = "N/A";
      servchargeAmountRow.csvValue.toFixed(2);
    }

    if (onlineSalesRow) {
      onlineSalesRow.result = "Read-Only "; // Keep this always "Success"
      onlineSalesRow.computedValue = "N/A";
      onlineSalesRow.csvValue.toFixed(2);
    }

    if (checkSalesRow) {
      checkSalesRow.result = "Read-Only";
      checkSalesRow.computeValue = "N/A";
      checkSalesRow.csvValue.toFixed(2);
    }

    if (gcSalesRow) {
      gcSalesRow.result = "Read-Only";
      gcSalesRow.computeValue = "N/A";
      gcSalesRow.csvValue.toFixed(2);
    }

    if (foodPandaSalesRow) {
      foodPandaSalesRow.result = "Read-Only";
      foodPandaSalesRow.computeValue = "N/A";
      foodPandaSalesRow.csvValue.toFixed(2);
    }

    if (grabSalesRow) {
      grabSalesRow.result = "Read-Only";
      grabSalesRow.computeValue = "N/A";
      grabSalesRow.csvValue.toFixed(2);
    }

    if (snrCitDiscRow) {
      snrCitDiscRow.result = "Read-Only";
      snrCitDiscRow.computeValue = "N/A";
      snrCitDiscRow.csvValue.toFixed(2);
    }

    if (pwdDiscRow) {
      pwdDiscRow.result = "Read-Only";
      pwdDiscRow.computeValue = "N/A";
      pwdDiscRow.csvValue.toFixed(2);
    }

    if (grossSalesRow) {
      const calculatedGross =
        computeValue("VAT_AMNT") +
        computeValue("VATABLE_SLS") +
        computeValue("NONVAT_SLS") +
        computeValue("VATEXEMPT_SLS") +
        computeValue("VATEXEMPT_AMNT") +
        computeValue("LOCAL_TAX") +
        computeValue("REFUND_AMT") +
        computeValue("PWD_DISC") +
        computeValue("SNRCIT_DISC") +
        computeValue("EMPLO_DISC") +
        computeValue("AYALA_DISC") +
        computeValue("STORE_DISC") +
        computeValue("OTHER_DISC") +
        computeValue("SCHRGE_AMT") +
        computeValue("OTHER_SCHR");

      const isGrossSuccess =
        Math.abs(calculatedGross - grossSalesRow.csvValue) < 0.01;
      grossSalesRow.result = isGrossSuccess ? "Success" : "Failure";
      grossSalesRow.computedValue = calculatedGross.toFixed(2);
    }

    // Calculate DISCOUNTS
    if (discountRow) {
      const calculatedDiscount =
        computeValue("SNRCIT_DISC") +
        computeValue("PWD_DISC") +
        computeValue("EMPLO_DISC") +
        computeValue("AYALA_DISC") +
        computeValue("STORE_DISC") +
        computeValue("OTHER_DISC");

      const isDiscountSuccess =
        Math.abs(calculatedDiscount - discountRow.csvValue) < 0.01;
      discountRow.result = isDiscountSuccess ? "Success" : "Failure";
      discountRow.computedValue = calculatedDiscount.toFixed(2);
    }

    // Calculate NO_DISC
    if (noDiscountRow) {
      const calculatedNoDiscounts =
        computeValue("NO_SNRCIT") +
        computeValue("NO_PWD") +
        computeValue("NO_EMPLO") +
        computeValue("NO_AYALA") +
        computeValue("NO_STORE") +
        computeValue("NO_OTHER_DISC");

      const isNoDiscountSuccess =
        Math.abs(calculatedNoDiscounts - noDiscountRow.csvValue) < 0.01;
      noDiscountRow.result = isNoDiscountSuccess ? "Success" : "Failure";
      noDiscountRow.computedValue = calculatedNoDiscounts.toFixed(2);
    }

    // Calculate CARD_SLS
    if (cardSalesRow) {
      const calculatedCardSales =
        computeValue("MASTERCARD_SLS") +
        computeValue("VISA_SLS") +
        computeValue("AMEX_SLS") +
        computeValue("DINERS_SLS") +
        computeValue("JCB_SLS");

      const isCardSuccess =
        Math.abs(calculatedCardSales - cardSalesRow.csvValue) < 0.01;
      cardSalesRow.result = isCardSuccess ? "Success" : "Failure";
      cardSalesRow.computedValue = calculatedCardSales.toFixed(2);
    }

    // Calculate DCARD_SLS
    if (dcardSalesRow) {
      const calculatedDCardSales =
        computeValue("MASTERDEBIT_SLS") + computeValue("VISADEBIT_SLS");

      const isDCardSuccess =
        Math.abs(calculatedDCardSales - dcardSalesRow.csvValue) < 0.01;
      dcardSalesRow.result = isDCardSuccess ? "Success" : "Failure";
      dcardSalesRow.computedValue = calculatedDCardSales.toFixed(2);
    }

    // Calculate EPAY_SLS
    if (ePaySalesRow) {
      const calculatedEpaySales =
        computeValue("GCASH_SLS") +
        computeValue("PAYMAYA_SLS") +
        computeValue("ALIPAY_SLS") +
        computeValue("WECHAT_SLS");

      const isEPaySuccess =
        Math.abs(calculatedEpaySales - ePaySalesRow.csvValue) < 0.01;
      ePaySalesRow.result = isEPaySuccess ? "Success" : "Failure";
      ePaySalesRow.computedValue = calculatedEpaySales.toFixed(2);
    }

    return processedResults.filter((r) => r.result);
  };

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h3>EOD File Uploader</h3>
      <input
        type="file"
        accept=".csv"
        className="form-control"
        onChange={handleFileUpload}
      />
      {results.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Result</th>
              <th>Computed Value</th>
              <th>CSV Value</th>
            </tr>
          </thead>
          <tbody>
            {results.map((res, index) => (
              <tr key={index}>
                <td>{res.type}</td>
                <td>
                  {" "}
                  <span className="badge bg-success">{res.result}</span>
                </td>
                <td>{res.computedValue ? res.computedValue : "N/A"}</td>
                <td>{res.csvValue.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default EODFileUploader;
