import React, { useEffect } from "react";
import FileUploader from "./components/FileUploader";
import MultiFileUploader from "./components/MultiFileUploader";
import EODFileUploader from "./components/EODFileUploader";
import MainFileUploader from "./components/MainFileUploader";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  useEffect(() => {
    // Prevent back navigation by adding an entry to the history stack and capturing the 'popstate' event
    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
    };

    // Disable back navigation when component mounts
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", disableBackButton);

    return () => {
      // Clean up by removing the event listener when the component unmounts
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <div className="row">
        <div className="col-md-12">
          <MainFileUploader />
        </div>
      </div>
    </div>
  );
};

export default App;
