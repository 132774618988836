// Helper function to parse the CSV-style text from the .txt file
export const parseFile = (text) => {
  return text.split("\n").map((row) => row.split(","));
};

// Function to calculate RAW GROSS and RAW VAT from the HEADER file
export const calculateRawGrossAndVat = (rows) => {
  const fieldsForGross = [
    "TOTDISC",
    "TOTREF",
    "TOTCAN",
    "VAT",
    "LOCALTX",
    "SERVCHARGE",
    "DLYLOCTAX",
  ];

  const indicesForGross = fieldsForGross.reduce((acc, field) => {
    const index = rows[0].indexOf(field);
    if (index !== -1) acc[field] = index;
    return acc;
  }, {});

  const rawGross = Object.values(indicesForGross).reduce((gross, index) => {
    return gross + (parseFloat(rows[1][index]) || 0);
  }, 0);

  const vatIndex = rows[0].indexOf("VAT");
  const nonVatIndex = rows[0].indexOf("NOTAXSALE");
  const rawVat = vatIndex !== -1 ? parseFloat(rows[1][vatIndex]) || 0 : 0;

  return { rawGross, rawVat };
};

export const processHeaderFile = (rows) => {
  // Indexes for each relevant field
  const dlySaleIndex = rows[0].indexOf("DLYSALE");
  const vatIndex = rows[0].indexOf("VAT");
  const nonVatIndex = rows[0].indexOf("NOTAXSALE");
  const servchargeIndex = rows[0].indexOf("SERVCHARGE");
  const beginvIndex = rows[0].indexOf("BEGINV");
  const endinvIndex = rows[0].indexOf("ENDINV");
  const totdiscIndex = rows[0].indexOf("TOTDISC");
  const totrefIndex = rows[0].indexOf("TOTREF");
  const totcanIndex = rows[0].indexOf("TOTCAN");

  // Debugging logs
  console.log("Headers Row:", rows[0]);
  console.log(
    "Indexes -> SERVCHARGE:",
    servchargeIndex,
    "TOTDISC:",
    totdiscIndex,
    "TOTREF:",
    totrefIndex,
    "TOTCAN:",
    totcanIndex
  );

  // Initialize variables
  let dlySaleValue = 0,
    dlysale = 0,
    vatValue = 0,
    vat = 0;
  let nonVatValue = 0,
    servchargeValue = 0,
    servcharge = 0;
  let nonVat = 0,
    beginv = 0,
    endinv = 0,
    headerTotDisc = 0,
    headerTotRef = 0;

  // Parse values
  if (dlySaleIndex !== -1) {
    dlySaleValue = parseFloat(rows[1][dlySaleIndex]) || 0;
    dlysale = dlySaleValue;
  }
  if (vatIndex !== -1) {
    vatValue = parseFloat(rows[1][vatIndex]) || 0;
    vat = vatValue;
  }
  if (nonVatIndex !== -1) {
    nonVatValue = parseFloat(rows[1][nonVatIndex]) || 0;
    nonVat = nonVatValue;
  }
  if (servchargeIndex !== -1) {
    const rawServchargeValue = rows[1][servchargeIndex];
    console.log("Raw SERVCHARGE Value:", rawServchargeValue);
    servchargeValue = parseFloat(rawServchargeValue) || 0;
    servcharge = servchargeValue;
  }
  if (totdiscIndex !== -1) {
    headerTotDisc = parseFloat(rows[1][totdiscIndex]) || 0;
  }
  if (totrefIndex !== -1 && totcanIndex !== -1) {
    const totRefValue = parseFloat(rows[1][totrefIndex]) || 0;
    const totCanValue = parseFloat(rows[1][totcanIndex]) || 0;
    headerTotRef = totRefValue + totCanValue;
  }

  if (beginvIndex !== -1 && endinvIndex !== -1) {
    beginv = parseFloat(rows[1][beginvIndex]) || 0;
    endinv = parseFloat(rows[1][endinvIndex]) || 0;
  }

  const transCount = endinv - beginv + 1;

  // Log the computed values
  console.log(
    "Parsed Values -> SERVCHARGE:",
    servcharge,
    "TOTDISC:",
    headerTotDisc,
    "TOTREF + TOTCAN:",
    headerTotRef
  );

  return {
    dlySaleValue: dlySaleValue + vatValue,
    dlysale,
    vat,
    nonVat,
    beginv,
    endinv,
    transCount,
    servcharge,
    headerTotDisc,
    headerTotRef,
  };
};

// Function to calculate SALES and TRANCNT from the DETAILS file
export const processDetailsFile = (rows) => {
  const salesIndex = rows[0].indexOf("SALES");
  const trancntIndex = rows[0].indexOf("TRANCNT");

  const salesSum =
    salesIndex !== -1
      ? rows
          .slice(1)
          .reduce((sum, row) => sum + (parseFloat(row[salesIndex]) || 0), 0)
      : 0;

  const trancntSum =
    trancntIndex !== -1
      ? rows
          .slice(1)
          .reduce((sum, row) => sum + (parseFloat(row[trancntIndex]) || 0), 0)
      : 0;

  return { salesSum, trancntSum };
};
